// (function ($) {
//
//     /**
//      * initializeBlock
//      *
//      * Adds custom JavaScript to the blockname-block HTML.
//      *
//      * @date    15/4/19
//      * @since   1.0.0
//      *
//      * @return  void
//      */
//     const initializeBlock = function ($block) {
//         const document = $("document");
//         const filterList = $block.find(".filter");
//         const filterButtons = filterList.find(".filter-btn");
//         const cards = $block.find(".posts-cards");
//
//         let conferenceIndex = 0;
//
//         cards.each(function () {
//             $(this).css("viewTransitionName", `conf-${++conferenceIndex}`);
//         });
//
//         filterButtons.on("click", function (e) {
//             let confCategory = $(this).attr("data-filter");
//
//             if (!document.data("startViewTransition")) {
//                 updateActiveButton($(this));
//                 filterEvents(confCategory);
//             }
//
//             document.data("startViewTransition", function () {
//                 updateActiveButton($(this));
//                 filterEvents(confCategory);
//             });
//             setTimeout(function () {
//                 $('.select-selected > .your-icon-class').on("click", function () {
//                     filterEvents('all');
//                 });
//             }, 1000);
//
//         });
//
//         function updateActiveButton(newButton) {
//             filterList.find(".active").removeClass("active");
//             newButton.addClass("active");
//         }
//
//         function filterEvents(filter) {
//             cards.each(function () {
//                 let eventCategory = $(this).attr("data-category");
//
//                 if (filter === "all" || eventCategory.includes(filter)) {
//                     $(this).removeClass("hidden");
//                 } else {
//                     $(this).addClass("hidden", "");
//                 }
//             });
//         }
//
//         $('.posts-cards').each(function(index) {
//             const $card = $(this);
//
//             $card.on('mouseover', function() {
//                 if (index > 0) {
//                     $('.posts-cards').eq(index - 1).addClass('hide-after');
//                 }
//             });
//
//             $card.on('mouseout', function() {
//                 if (index > 0) {
//                     $('.posts-cards').eq(index - 1).removeClass('hide-after');
//                 }
//             });
//         });
//
//     }
//
//     // Initialize each block on page load (front end).
//     $(document).ready(function () {
//         $('.post-carousel-block').each(function () {
//             initializeBlock($(this));
//         });
//     });
//
//     // Initialize dynamic block preview (editor).
//     if (window.acf) {
//         window.acf.addAction('render_block_preview/type=post-carousel-block', initializeBlock);
//     }
//
// })(jQuery);